import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './auth/admin.guard';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { Error404Component } from './error404/error404.component';
import { CustomerCompetenceResolver } from './exercise-sets/CustomerCompetenceResolver';
import { ExerciseSetsResolver } from './exercise-sets/ExerciseSetsResolver';
import { MyExerciseSetsResolver } from './exercise-sets/MyExerciseSetsResolver';
import { SelectedExerciseResolver } from './exercise-sets/SelectedExerciseResolver';
import { SelectedExerciseSetResolver } from './exercise-sets/SelectedExerciseSetResolver';
import { ExerciseSetContentComponent } from './exercise-sets/exercise-set-content/exercise-set-content.component';
import { MatrixResolver } from './exercise-sets/exercise-set-matrix/MatrixResolver';
import { ExerciseSetMatrixComponent } from './exercise-sets/exercise-set-matrix/exercise-set-matrix.component';
import { ExerciseSetScaleComponent } from './exercise-sets/exercise-set-scale/exercise-set-scale.component';
import { ExerciseSetsAreaComponent } from './exercise-sets/exercise-sets-area.component';
import { ExercisePrintComponent } from './print-layout/exercise-print/exercise-print.component';
import { RecoverpwComponent } from './recoverpw/recoverpw.component';
import { StartpageAreaComponent } from './startpage-area/startpage-area.component';
import { UserListResolver } from './useradmin/UserListResolver';
import { SelectedUserResolver } from './useradmin/useradmin-detaileduser/SelectedUserResolver';
import { UseradminDetaileduserComponent } from './useradmin/useradmin-detaileduser/useradmin-detaileduser.component';
import { UseradminComponent } from './useradmin/useradmin.component';

const appRoutes: Routes = [
  {
    path: 'home',
    component: StartpageAreaComponent,
    canActivate: [AuthGuard],
    resolve: {
      exerciseSets: MyExerciseSetsResolver,
      userList: UserListResolver
    }
  },
  {
    path: 'api/sso/cb/:tennantId',
    redirectTo: 'home',
  },
  {
    path: 'selectedset',
    component: ExerciseSetsAreaComponent,
    canActivate: [AuthGuard],
    resolve: {
      exerciseSets: MyExerciseSetsResolver,
      userList: UserListResolver
    },
    children: [
      {
        canActivate: [AuthGuard],
        path: ':id/scala',
        component: ExerciseSetScaleComponent,
        resolve: {
          selectedExerciseSet: SelectedExerciseSetResolver
        }
      },
      {
        canActivate: [AuthGuard],
        path: ':id/matrix',
        component: ExerciseSetMatrixComponent,
        resolve: {
          exerciseSets: ExerciseSetsResolver,
          selectedExerciseSet: SelectedExerciseSetResolver,
          matrixData: MatrixResolver
        }
      },
      {
        canActivate: [AuthGuard],
        path: ':id/custom/:dimensionid',
        component: ExerciseSetContentComponent,
        resolve: {
          exerciseSets: ExerciseSetsResolver,
          selectedExerciseSet: SelectedExerciseSetResolver,
          selectedCustomerdimension: CustomerCompetenceResolver
        }
      },
      {
        canActivate: [AuthGuard],
        path: ':id/:exerciseid',
        component: ExerciseSetContentComponent,
        resolve: {
          exerciseSets: ExerciseSetsResolver,
          userList: UserListResolver,
          selectedExerciseSet: SelectedExerciseSetResolver,
          selectedExercise: SelectedExerciseResolver
        },
      },
      {
        canActivate: [AuthGuard],
        path: ':id',
        component: ExerciseSetContentComponent,
        resolve: {
          exerciseSets: ExerciseSetsResolver,
          selectedExerciseSet: SelectedExerciseSetResolver,
          userList: UserListResolver
        },
      }
    ]
  },
  {
    path: 'useradmin',
    component: UseradminComponent,
    canActivate: [AuthGuard, AdminGuard],
    resolve: {
      userList: UserListResolver,
      selectedUser: SelectedUserResolver
    },
    children: [
      {
        path: 'selectedUser/:id',
        component: UseradminDetaileduserComponent,
        resolve: {
          userList: UserListResolver,
          selectedUser: SelectedUserResolver
        },
      }
    ]
  },
  {
    path: 'print/exercise-set/:id/exercise/:exerciseid',
    canActivate: [AuthGuard],
    component: ExercisePrintComponent,
    resolve: {
      selectedExercise: SelectedExerciseResolver,
      selectedExerciseSet: SelectedExerciseSetResolver
    }
  },
  { path: 'auth', component: AuthComponent },
  { path: 'recoverpw/:key', component: RecoverpwComponent },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false, enableTracing: false })],
  exports: [RouterModule],
  providers: [
    MatrixResolver,
    ExerciseSetsResolver,
    MyExerciseSetsResolver,
    SelectedExerciseSetResolver,
    UserListResolver,
    SelectedUserResolver,
    SelectedExerciseResolver,
    CustomerCompetenceResolver
  ]
})
export class AppRoutingModule {
}

/*
{
    path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      {
        path: 'exercise-set/:id/exercise/:exerciseid',
        pathMatch: 'full',
        component: ExercisePrintComponent,
        resolve: {
          data: SelectedExerciseResolver
        }
      }
    ]
  },
 */
