import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../user.model';
import { UseradminComponent } from '../useradmin.component';
import { UseradminService } from '../useradmin.service';

@Component({
  selector: 'app-useradmin-detaileduser',
  templateUrl: './useradmin-detaileduser.component.html',
  styleUrls: ['./useradmin-detaileduser.component.scss']
})
export class UseradminDetaileduserComponent implements OnInit {
  @Input() currentDetailedUser: User;
  @Input() Users: User[];

  constructor(private activeRoute: ActivatedRoute, private useradminService: UseradminService, private route: ActivatedRoute, private useradmin: UseradminComponent) {
    this.activeRoute.data.subscribe(value => {
      this.Users = value.userList;
      this.currentDetailedUser = value.selectedUser;
      this.useradmin.currentDetailedUser = value.selectedUser;
    });

  }

  ngOnInit(): void {
  }

  allUsersLoaded() {
  }

  onResetBtnClick(user) {
    this.useradminService.requestPWRecovery(user.email).subscribe(msg => {
      alert('An die angegebene Email-Adresse wurde eine Anleitung zum Passwort zurücksetzen gesendet!');
    });
  }
}
