import { Exercise } from './exercise/exercise.model';
import { ScaleEntity } from './exercise-set-scale/exercise-scale.model';
import { ProfilmSubDimension } from '../shared/profilm-sub-dimension.model';
import { PreDefinedCompetencies } from '../shared/profilm.types';
import { AcrossCompetencie } from './across-competencies/across-competencie.model';

export class ExerciseSet {
  // tslint:disable-next-line:variable-name
  _id: string;
  creationDate: string;
  updateDate: string;
  updateUserId: string;
  description = '';
  users = [];
  exercises: Exercise[] = [];
  acrossCompetencies: PreDefinedCompetencies[];
  scale: ScaleEntity[] = [];
  lastSavedJson = '';
  customername?: string;
  folder?: string;
  inEnglish?: boolean;

  public static createByObject(o: ExerciseSet): ExerciseSet {
    const newExerciseSet = new ExerciseSet(o.name);
    newExerciseSet._id = o._id;
    newExerciseSet.acrossCompetencies = ExerciseSet.createAcrossCompetencies(o.acrossCompetencies);
    newExerciseSet.description = o.description;
    newExerciseSet.users = o.users;
    newExerciseSet.exercises = o.exercises;
    newExerciseSet.creationDate = o.creationDate;
    newExerciseSet.updateDate = o.updateDate;
    newExerciseSet.updateUserId = o.updateUserId;
    newExerciseSet.scale = o.scale;
    newExerciseSet.customername = o.customername;
    newExerciseSet.folder = o.folder;
    newExerciseSet.inEnglish = o.inEnglish || false;
    return newExerciseSet;
  }
  public static createAcrossCompetencies(array: PreDefinedCompetencies[]): PreDefinedCompetencies[] {
    const result: PreDefinedCompetencies[] = [];
    if (array) {
      array.forEach((a: PreDefinedCompetencies) => {
        if (!(a instanceof ProfilmSubDimension) && a.dimensions) {
          result.push(AcrossCompetencie.createByObject(a));
        } else if (!(a instanceof AcrossCompetencie)) {
          result.push(new ProfilmSubDimension(a.de, a.en, a.anchor, a.questions));
        }
      });
    }
    return result;
  }

  constructor(public name: string) {
  }

  public removeDirtyFlag() {
    const temp = ExerciseSet.createByObject(this);
    temp.updateDate = '';
    temp.lastSavedJson = '';
    this.lastSavedJson = JSON.stringify(temp);
  }

  public lastDirtyCall = new Date(0).getTime()
  public lastDirtyResult = false


  public getIsDirty(): boolean {

    if ((this.lastDirtyCall + 500 > new Date().getTime())) {
      this.lastDirtyCall = new Date().getTime()
      return this.lastDirtyResult
    }
    this.lastDirtyCall = new Date().getTime()

    const currentSet = JSON.parse(JSON.stringify(this));
    currentSet.lastSavedJson = '';
    currentSet.updateDate = '';
    if (this.lastSavedJson === '') {
      return true;
    }
    const string1 = JSON.stringify(ExerciseSet.createByObject(JSON.parse(this.lastSavedJson)));
    const string2 = JSON.stringify(ExerciseSet.createByObject(currentSet));
    this.lastDirtyResult = string1 !== string2
    return this.lastDirtyResult;
  }
}
