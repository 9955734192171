import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UseradminService } from '../../useradmin/useradmin.service';
import { ExerciseSet } from '../exercise-set.model';

@Component({
  selector: 'app-sets-menu',
  templateUrl: './sets-menu.component.html',
  styleUrls: ['./sets-menu.component.scss']
})
export class SetsMenuComponent implements OnInit {
  @Input() exerciseset: ExerciseSet;
  @Input() label = 'Dropdown';

  constructor(public router: Router, public userservice: UseradminService) {
  }

  /*findUsername(id) {
    if (id !== undefined) {
      const index = this.users.findIndex(user => user._id === id);
      if (index > -1) {
        return this.users[index].name;
      }
    }
  }*/
  ngOnInit(): void {
  }
}
