import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ExerciseSet } from '../exercise-sets/exercise-set.model';
import { ProfilmSubDimension } from '../shared/profilm-sub-dimension.model';
import { ProfilmService } from '../shared/profilm.service';
import { PreDefinedCompetencies } from '../shared/profilm.types';

@Component({
  selector: 'app-customer-dimension',
  templateUrl: './customer-dimension.component.html',
  styleUrls: ['./customer-dimension.component.scss']
})
export class CustomerDimensionComponent implements OnInit {
  @Input() exerciseset: ExerciseSet;
  @ViewChild('dimensionSelect') dimensionSelect: NgSelectComponent;
  public selectedCustomerdimension: PreDefinedCompetencies;
  public alldimensions: ProfilmSubDimension[];
  constructor(public profilMservice: ProfilmService, private aRoute: ActivatedRoute) {
    aRoute.data.subscribe(d => {
      this.selectedCustomerdimension = d.selectedCustomerdimension;

      if (!(this.selectedCustomerdimension instanceof ProfilmSubDimension) && this.selectedCustomerdimension.dimensions) {
        const dimensions = this.selectedCustomerdimension.dimensions;
        this.selectedCustomerdimension.dimensions = [];
        dimensions.forEach(di => {
          // @ts-ignore
          this.selectedCustomerdimension.dimensions.push(new ProfilmSubDimension(di.de, di.en, di.anchor, di.questions));
        });
      }
    });
    this.alldimensions = profilMservice.getAllSubDimensions();
  }

  ngOnInit(): void {
  }
  isPrm() {
    return this.selectedCustomerdimension instanceof ProfilmSubDimension;

  }
  onAddDimension(e: any) {
    if (!e) {
      return;
    }

    const subdim: ProfilmSubDimension = e;
    // @ts-ignore
    this.selectedCustomerdimension.dimensions.push(new ProfilmSubDimension(subdim.de, subdim.en, subdim.anchor, subdim.questions));
    // this.selectedCustomerdimension.dimensions.push(Dimension.createFromProfilmSubDimension(dimension));
    // this.service.onExerciseSelectionChanged.getValue().dimensions.push(Dimension.createFromProfilmSubDimension(dimension));
    this.dimensionSelect.clearModel();
  }
  groupByFn = (item) => item.parentDimension.name;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].parentDimension.name });
  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.parentDimension.name.toLowerCase().indexOf(term) > -1;
  }
  removeDimension(dimension: any, index: number) {
    // @ts-ignore
    this.selectedCustomerdimension.dimensions.splice(index, 1);
  }

}
