<div class="form-group">
  <label>Bearbeiter</label>

  <div class="row">
    <div class="col">
      <ng-select
        [items]="selectableUsers"
        bindValue="_id"
        bindLabel="name"
        (change)="onAddUser($event)"
        class="form-control-sm  form-control"
      >
        <!--DO NOT NEED Temlate for seected ITEMS-->
        <ng-template
          let-item="item"
          ng-label-tmp
        >
          <div class="row">
            <div class="col">
              <span> yy {{ item | json}}</span>
            </div>
          </div>
        </ng-template>
        <!--ITEM IN LIST-->
        <ng-template
          let-index="index"
          let-item="item"
          let-search="searchTerm"
          ng-option-tmp
        >
          <div class="row">
            <div class="col">
              <span>
                <span class="user-initials user-initials-sm me-2">
                  {{ item.name | initials}} </span>{{ item.name }} </span>
            </div>

          </div>
        </ng-template>


      </ng-select>
    </div>
  </div>
  <div
    class="user-img cursor-default"
    *ngFor="let user of selectedUsers"
    [ngClass]="{'user-img-more-user':selectedUsers.length>3}"
  >
    <span
      class="user-initials"
      placement="top"
      ngbTooltip="Tooltip on top"
    >{{user.name | initials}}</span>
    <i
      class="ri-close-circle-fill text-danger profile-status cursor-pointer"
      (click)="onRemoveUser(user._id)"
    ></i>
  </div>
</div>