<div class="row pt-4">
  <div class="col-2 offset-1">
    <ul class="nav justify-content-end invisible">
      <li class="nav-item>">
        <button
          type="button"
          class="btn cursor-pointer nav-link btn-sm btn-slick text-white"
        >
          Add new User
          <i class="ri-add-line"></i>
        </button>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <h5>Menü</h5>
        <div
          class="list-group"
          style="padding-bottom: 0px; padding-right: 0px;"
        >
          <a
            class="list-group-item list-group-item-action active"
            style="cursor: pointer;"
          >
            <i class="ri-user-line"></i>
            Benutzer</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <ul class="nav justify-content-end">
      <li class="nav-item>">
        <button
          type="button"
          class="btn cursor-pointer nav-link btn-sm btn-slick text-white"
          (click)="newUserIsOpen= true"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{exact:true}"
          [routerLink]="['/useradmin']"
        >
          Benutzer hinzufügen
          <i class="ri-add-line"></i>
        </button>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <h5>
          <i class="ri-user-line"></i>
          Benutzerliste
        </h5>
        <div class="row">
          <div class="col">
            <div
              class="list-group"
              style="padding-bottom: 0px; padding-right: 0px;"
            >
              <a
                class="list-group-item list-group-item-action"
                *ngFor="let user of Users; let i = index"
                (click)="showUserDetails(user)"
                style="cursor:pointer"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact:true}"
                [routerLink]="['/useradmin','selectedUser',user._id]"
              >
                {{user.name || "KEIN NAME"}}
                <span
                  *ngIf="user.isadmin"
                  class="badge bg-secondary float-end"
                >Admin</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-5 offset-0">
    <ul class="nav justify-content-end">
      <li class="nav-item">
        <button
          type="button"
          [ngClass]="{'invisible': newUserIsOpen}"
          class="btn cursor-pointer nav-link btn-sm btn-slick text-white text-danger"
          style="margin-right:10px"
          (click)="this.openUserAdminModal()"
        >
          Löschen
          <i class="ri-delete-bin-line"></i>
        </button>
      </li>
      <li class="nav-item">
        <button
          type="button"
          [ngClass]="{'invisible': newUserIsOpen}"
          class="btn cursor-pointer nav-link btn-sm btn-slick text-white"
          (click)="onSaveBtnClick(currentDetailedUser)"
        >
          Speichern
          <i
            class="ri-save-line"
            *ngIf="!saved"
          ></i>
          <i
            class="ri-check-line"
            *ngIf="saved"
          ></i>
        </button>
      </li>
    </ul>
    <div class="card">
      <div class="card-body">
        <h5>
          <i class="ri-user-line"></i>
          Einzelbenutzer
        </h5>
        <div style="padding-bottom: 0px; padding-right: 0px;">
          <div *ngIf="newUserIsOpen">
            <h3 style="display: contents;color: #384d63">Benutzer hinzufügen</h3>
            <div class="nav-user-img2 cursor-default float-end unselectable invisible">
              <span
                class="round-nav2"
                placement="top"
                ngbTooltip="Tooltip on top"
              >BB</span>
            </div>
            <br><br>
            <form
              #addUserForm="ngForm"
              (ngSubmit)="onSubmit(addUserForm)"
            >
              <div class="form-group">
                <label for="name">Name</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  ngModel
                  name="name"
                  required
                  placeholder="Name"
                />
              </div>
              <div class="form-group">
                <label for="email">E-Mail</label>
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  ngModel
                  name="email"
                  required
                  email
                  placeholder="E-Mail"
                />
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  id="isadmin"
                  class="form-check-input"
                  ngModel
                  name="isadmin"
                  isadmin
                  placeholder="xxx"
                />
                <small>Admin</small>
              </div>
              <div class="form-group"></div>
              <button
                class="btn btn-primary btn-block"
                type="submit"
                style="width:200px"
                [disabled]="!addUserForm.valid"
              >
                Neuen Benutzer anlegen
              </button>
            </form>
          </div>
        </div>
        <router-outlet (activate)="newUserIsOpen= false"></router-outlet>
      </div>
    </div>
  </div>
</div>