import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '../../shared/layout.service';
import { ExerciseSet } from '../exercise-set.model';

@Component({
  selector: 'app-exercise-set-scale',
  templateUrl: './exercise-set-scale.component.html',
  styleUrls: ['./exercise-set-scale.component.scss']
})
export class ExerciseSetScaleComponent implements OnInit {
  public exerciseset: ExerciseSet = new ExerciseSet('');

  constructor(public layout: LayoutService, activeRoute: ActivatedRoute) {
    activeRoute.data.subscribe(value => {
      this.exerciseset = value.selectedExerciseSet;
    });
  }

  ngOnInit(): void {
  }

  onAddBtnClick() {
    this.exerciseset.scale.push({ value: '', desc: '' });
  }

  onTrashBtnClick(s) {
    const index = this.exerciseset.scale.indexOf(s);
    this.exerciseset.scale.splice(index, 1);
  }
}
