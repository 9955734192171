import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { User } from '../../useradmin/user.model';
import { UseradminService } from '../../useradmin/useradmin.service';
import { ExerciseSet } from '../exercise-set.model';
import { ExerciseSetService } from '../exercise-set.service';

@Component({
  selector: 'app-exercise-user-list',
  templateUrl: './exercise-user-list.component.html',
  styleUrls: ['./exercise-user-list.component.scss']
})
export class ExerciseUserListComponent implements OnInit, OnChanges {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input() exerciseset: ExerciseSet;
  users: User[] = [];
  selectableUsers: User[] = [];
  selectedUsers: User[] = [];

  constructor(private userAdminService: UseradminService, private exerciseSetService: ExerciseSetService, private route: ActivatedRoute) {
  }

  private filterSortUsers() {
    this.selectableUsers = [];
    this.selectedUsers = [];
    this.users.sort((a: User, b: User) => {
      return a.name.localeCompare(b.name);
    });
    this.users.forEach((user: User) => {
      if (this.exerciseset.users.indexOf(user._id) === -1) {
        this.selectableUsers.push(user);
      } else {
        this.selectedUsers.push(user);
      }
    });
  }

  async ngOnInit() {
    this.users = await this.userAdminService.getUsers();
    this.filterSortUsers();
  }

  onAddUser($event: User) {
    if (!!$event) {
      const isInExercise = this.exerciseset.users.indexOf($event._id);
      if (isInExercise === -1) {
        this.exerciseset.users.push($event._id);
        this.filterSortUsers();
        this.ngSelectComponent.clearModel();
      }
    }
  }

  onRemoveUser(userId: string) {
    const isInExercise = this.exerciseset.users.indexOf(userId);
    if (isInExercise >= 0 && this.exerciseset.users.length > 1) {
      this.exerciseset.users.splice(isInExercise, 1);
      this.filterSortUsers();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.exerciseset.firstChange) {
      this.filterSortUsers();
    }
  }
}
