import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgSelectConfig, NgSelectModule } from "@ng-select/ng-select";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthComponent } from "./auth/auth.component";
import { AuthService } from "./auth/auth.service";
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CustomerDimensionComponent } from './customer-dimension/customer-dimension.component';
import { EmptyComponent } from "./empty/empty.component";
import { AcrossCompetenciesComponent } from './exercise-sets/across-competencies/across-competencies.component';
import { AnchorDetailsComponent } from "./exercise-sets/exercise-details/dimension-details/anchor-details/anchor-details.component";
import { DimensionDetailsComponent } from "./exercise-sets/exercise-details/dimension-details/dimension-details.component";
import { ExerciseDetailsComponent } from "./exercise-sets/exercise-details/exercise-details.component";
import { ExerciseListItemComponent } from "./exercise-sets/exercise-list/exercise-list-item/exercise-list-item.component";
import { ExerciseListComponent } from "./exercise-sets/exercise-list/exercise-list.component";
import { ExerciseSetContentComponent } from "./exercise-sets/exercise-set-content/exercise-set-content.component";
import { ExerciseSetEditorComponent } from "./exercise-sets/exercise-set-editor/exercise-set-editor.component";
import { ExerciseSetMatrixComponent } from "./exercise-sets/exercise-set-matrix/exercise-set-matrix.component";
import { ExerciseSetScaleComponent } from "./exercise-sets/exercise-set-scale/exercise-set-scale.component";
import { ExerciseSetProvider } from "./exercise-sets/exercise-set.provider";
import { ExerciseSetsAreaComponent } from "./exercise-sets/exercise-sets-area.component";
import { ExerciseSetsSidebarContentComponent } from "./exercise-sets/exercise-sets-sidebar-content/exercise-sets-sidebar-content.component";
import { ExerciseUserListComponent } from "./exercise-sets/exercise-user-list/exercise-user-list.component";
import { SetsMenuOperationsComponent } from "./exercise-sets/sets-menu-operations/sets-menu-operations.component";
import { SetsMenuComponent } from "./exercise-sets/sets-menu/sets-menu.component";
import { SetsModiefiedLabelComponent } from './exercise-sets/sets-menu/sets-modiefied-label/sets-modiefied-label.component';
import { TimediffFromDatePipe } from "./pipes/timediff-from-date.pipe";
import { UserInitialsPipe } from "./pipes/user-initials.pipe";
import { ExercisePrintComponent } from "./print-layout/exercise-print/exercise-print.component";
import { ExerciseSetPrintNewComponent } from "./print-layout/exercise-set-print-new/exercise-set-print-new.component";
import { ExerciseSetPrintComponent } from "./print-layout/exercise-set-print/exercise-set-print.component";
import { PrintLayoutComponent } from "./print-layout/print-layout.component";
import { PrmCatalogComponent } from "./prm-catalog/prm-catalog.component";
import { RecoverpwComponent } from "./recoverpw/recoverpw.component";
import { ExerciseTypesCheckBoxComponent } from "./shared/exercise-types-check-box/exercise-types-check-box.component";
import { ProfilmService } from "./shared/profilm.service";
import { StartpageAreaComponent } from "./startpage-area/startpage-area.component";
import { StartpageContentComponent } from "./startpage-area/startpage-content/startpage-content.component";
import { UseradminDetaileduserComponent } from "./useradmin/useradmin-detaileduser/useradmin-detaileduser.component";
import { UseradminComponent } from "./useradmin/useradmin.component";

@NgModule({
  declarations: [
    AppComponent,
    ExerciseSetsAreaComponent,
    ExerciseSetsSidebarContentComponent,
    ExerciseSetContentComponent,
    ExerciseListComponent,
    ExerciseSetEditorComponent,
    AuthComponent,
    UseradminComponent,
    ExerciseListItemComponent,
    ExerciseTypesCheckBoxComponent,
    RecoverpwComponent,
    UseradminDetaileduserComponent,
    ExerciseDetailsComponent,
    ExerciseUserListComponent,
    UserInitialsPipe,
    StartpageAreaComponent,
    StartpageContentComponent,
    DimensionDetailsComponent,
    AnchorDetailsComponent,
    ExerciseSetMatrixComponent,
    TimediffFromDatePipe,
    ExerciseSetScaleComponent,
    TimediffFromDatePipe,
    PrintLayoutComponent,
    ExerciseSetPrintComponent,
    ExerciseSetPrintNewComponent,
    EmptyComponent,
    SetsMenuComponent,
    SetsMenuOperationsComponent,
    PrmCatalogComponent,
    ExercisePrintComponent,
    CustomerDimensionComponent,
    AcrossCompetenciesComponent,
    ConfirmModalComponent,
    SetsModiefiedLabelComponent
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgSelectModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    DragDropModule,
    CdkAccordionModule,
    ModalModule.forRoot()
  ],
  providers: [
    ProfilmService,
    AuthService,
    ExerciseSetProvider,
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
  constructor(
    profilmService: ProfilmService,
    authService: AuthService,
    private selectConfig: NgSelectConfig
  ) {
    this.selectConfig.notFoundText = "Nicht gefunden";
  }
}
