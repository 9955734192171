import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ProfilmAnchorModel } from './profilm-anchor.model';
import { ProfilmDimension } from './profilm-dimension.model';
import { ProfilmExerciseType } from './profilm-exercise-type.model';
import { ProfilmQuestionModel } from './profilm-question.model';
import { ProfilmSubDimension } from './profilm-sub-dimension.model';

@Injectable()
export class ProfilmService {
  public static language: "de" | 'en' = "de";
  private dimensions: ProfilmDimension[] = [];
  private itw: ProfilmExerciseType;
  private sp: ProfilmExerciseType;
  private fs: ProfilmExerciseType;
  private rs: ProfilmExerciseType;
  private introQuestions: ProfilmQuestionModel[] = [];
  private hashedTypeToSubdim: Map<string, ProfilmSubDimension[]>;

  constructor(private http: HttpClient) {
    /* tslint:disable */
    this.hashedTypeToSubdim = new Map<string, ProfilmSubDimension[]>();
  }


  public async getProfilmData() {
    const res = await firstValueFrom(this.http.get<any>("/api/templates"));
    res.templates.forEach((d) => {
      let newDimension = new ProfilmDimension(d.de, d.en, []);
      let newSubDimensions: ProfilmSubDimension[] = [];
      d.subDimensions.forEach((s) => {
        let newAnchor: ProfilmAnchorModel[] = [];
        let newQuestions: ProfilmQuestionModel[] = [];
        s.anchor.forEach((a) => {
          let newExerciseTypes: ProfilmExerciseType[] = [];
          a.exerciseTypes.forEach((et) => {
            newExerciseTypes.push(new ProfilmExerciseType(et.de, et.fullde, et.en, et.fullen));
          });
          newAnchor.push(new ProfilmAnchorModel(a.de, a.en, newExerciseTypes));
        });
        s.questions.forEach((q) => {
          newQuestions.push(new ProfilmQuestionModel(q.de, q.en));
        });
        newSubDimensions.push(new ProfilmSubDimension(s.de, s.en, newAnchor, newQuestions))
        newDimension.addSubDimension(newSubDimensions[newSubDimensions.length - 1]);
      });
      this.dimensions.push(newDimension);
    });
    res.questions.forEach((q) => {
      this.introQuestions.push(new ProfilmQuestionModel(q.de, q.en, q.type));
    });

    res.exercises.forEach((e) => {
      switch (e.de) {
        case "ITW":
          this.itw = new ProfilmExerciseType(e.de, e.fullde, e.en, e.fullen);
          break;
        case "PR":
          this.sp = new ProfilmExerciseType(e.de, e.fullde, e.en, e.fullen);
          break;
        case "FS":
          this.fs = new ProfilmExerciseType(e.de, e.fullde, e.en, e.fullen);
          break;
        case "RS":
          this.rs = new ProfilmExerciseType(e.de, e.fullde, e.en, e.fullen);
          break;
      }
    });
  }

  public static isLanguageDe(): boolean {
    return ProfilmService.language === "de";
  }

  public getItw(): ProfilmExerciseType {
    return this.itw;
  }

  public getSp(): ProfilmExerciseType {
    return this.sp;
  }

  public getFs(): ProfilmExerciseType {
    return this.fs;
  }

  public getRs(): ProfilmExerciseType {
    return this.rs;
  }

  public getExerciseTypes(): ProfilmExerciseType[] {
    return [this.getItw(), this.getSp(), this.getFs(), this.getRs()];
  }

  public getDimensions(): ProfilmDimension[] {
    return this.dimensions.slice();
  }

  public getSubDimensionByPrmIdendifier(
    prmSubDimIdent: string
  ): ProfilmSubDimension {
    for (let i = 0; i < this.dimensions.length; i++) {
      let dim: ProfilmDimension = this.dimensions[i];
      for (let y = 0; y < dim.subDimensions.length; y++) {
        if (dim.subDimensions[y].de == prmSubDimIdent || dim.subDimensions[y].en == prmSubDimIdent) {
          return dim.subDimensions[y];
        }
      }
    }
    return null;
  }

  public getIntroQuestions2(): ProfilmQuestionModel[] {
    return this.introQuestions.slice();
  }

  public getSubDimensions(exTypes: ProfilmExerciseType[]): ProfilmSubDimension[] {
    const hash: string = JSON.stringify(exTypes);
    if (this.hashedTypeToSubdim.has(hash)) {
      return this.hashedTypeToSubdim.get(hash);
    }
    const r: ProfilmSubDimension[] = [];
    this.dimensions.forEach((d: ProfilmDimension) => {
      const subdimensions: ProfilmSubDimension[] = d.getSubDimensions();
      r.push(
        ...subdimensions.filter((value, index) => {
          return value.hasAnkerOfTypes(exTypes);
        })
      );
    });
    this.hashedTypeToSubdim.set(hash, r);
    return r;
  }

  public getAllSubDimensions(): ProfilmSubDimension[] {
    const r: ProfilmSubDimension[] = [];
    this.dimensions.forEach((d: ProfilmDimension) => {
      r.push(...d.getSubDimensions());
    });
    return r;
  }

  public getAllAnchors(): ProfilmAnchorModel[] {
    const r: ProfilmAnchorModel[] = [];
    this.dimensions.forEach((d: ProfilmDimension) => {
      const subdimension = d.getSubDimensions();
      subdimension.forEach((s) => {
        r.push(...s.getAllAnker());
      });
    });
    return r;
  }
}
