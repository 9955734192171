import {Injectable} from '@angular/core';
import {ExerciseSet} from './exercise-set.model';

@Injectable()
export class ExerciseSetProvider {

  public storage: ExerciseSet;

  public constructor() {
  }

}
