<div class="row flyleaf exercise-row">
  <div class="col">
    <div class="row mb-1">
      <div
        class="col-6 ps-0"
        *ngIf="!(exerciseSet.customername && exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id))"
      >
        <img
          class="mb-2"
          src=" assets/ProfilM_Logo.png"
        >
        <h1>{{exerciseSet.name}}</h1>
      </div>
      <div
        class="col-6 ps-0"
        *ngIf="exerciseSet.customername && exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
      >
        <img
          class="mb-2"
          [src]="'/api/logo/load/' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id)._id + '?uploaddate=' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id).uploadDate"
        >
        <h1>{{exerciseSet.customername}}</h1>
        <h1>{{exerciseSet.name}}</h1>
      </div>
      <div class="col-6">
        <div class="row border border-prm">
          <div class="col-4 border-bottom border-end border-prm">{{isEnglish ? 'Name': 'Name'}}</div>
          <div class="col-8 border-bottom border-white prm-background-color-light"></div>
          <div class="col-4 border-bottom border-end border-prm">{{isEnglish ? 'Date': 'Datum'}}</div>
          <div class="col-8 border-bottom border-white prm-background-color-light"></div>
          <div class="col-4 border-end border-prm">{{isEnglish ? 'Observer': 'Beobachter*in'}}</div>
          <div class="col-8 prm-background-color-light"></div>
        </div>
      </div>
      <div class="col pt-2 mb-4 border-bottom border-prm"></div>
    </div>

    <div class="row mb-3">
      <div class="col ps-0">
        <h1 id="exerciseName">{{getHeaderText()}}</h1>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col border border-prm">
        <div class="row border fw-bold pt-2 pb-2 mb-2 prm-background-color">
          <div class="col text-white">{{isEnglish ? 'Competencies': 'Kompetenzen'}}</div>
        </div>
        <div
          class="row mb-1"
          *ngFor="let dimension of exercise.dimensions"
        >
          <div class="col">{{dimension.name}}</div>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col border border-prm scale">
        <div class="row border fw-bold mb-1 pt-2 pb-2 prm-background-color">
          <div class="col text-white">{{isEnglish ? 'Scale': 'Skala'}}</div>
        </div>
        <div
          class="row mb-1"
          *ngFor="let scale of exerciseSet.scale"
        >
          <div class="col-1 mr-8">
            <div class="number-circle">{{scale.value}}</div>
          </div>
          <div class="col scale-description">{{scale.desc}}</div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col notes">
        <div class="row border fw-bold pt-2 pb-2 prm-background-color">
          <div class="col text-white">{{isEnglish ? 'Notes': 'Notizen'}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Questions -->
<div
  class="row exercise-row"
  *ngIf="exerciseSetService.hasExerciseInterviewType(exercise)"
>
  <div class="col">
    <div class="row align-items-center pb-2">
      <div class="col-6 ps-0">
        <h1>{{exerciseSet.customername}}</h1>
        <h1>{{exerciseSet.name}}</h1>
      </div>
      <div
        class="col-6"
        style="padding-right:0px"
      >
        <img
          *ngIf="!exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          src="assets/ProfilM_Logo.png"
        >
        <img
          *ngIf="exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          [src]="'/api/logo/load/' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id)._id + '?uploaddate=' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id).uploadDate"
        >
      </div>
    </div>

    <div class="row">
      <div class="col mb-4 border-bottom border-prm"></div>
    </div>

    <div class="row">
      <div class="col fw-bold ps-0">{{isEnglish ? 'Overarching questions': 'Übergeordnete Fragen'}}</div>
    </div>

    <div class="row mt-3">
      <div class="col ps-0 pe-0">
        <table
          class="table dimension-table"
          *ngIf="hasExerciseIntroQuestions(exercise, false)"
        >
          <thead>
            <tr>
              <th class="prm-background-color">{{isEnglish ? 'Entry questions': 'Einstiegsfragen'}}</th>
            </tr>
          <tbody>
            <tr *ngFor="let question of exercise.introQuestions">
              <td *ngIf="question.type !== 'Schlussfrage'">{{question.text}}</td>
            </tr>
          </tbody>
        </table>

        <table
          class="table dimension-table"
          *ngIf="hasExerciseIntroQuestions(exercise, true)"
        >
          <thead>
            <tr>
              <th class="prm-background-color">{{isEnglish ? 'Final questions': 'Schlussfragen'}}</th>
            </tr>
          <tbody>
            <tr *ngFor="let question of exercise.introQuestions">
              <td *ngIf="question.type === 'Schlussfrage'">{{question.text}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col notes">
        <div class="row border fw-bold pt-2 pb-2 prm-background-color">
          <div class="col text-white">{{isEnglish ? 'Notes': 'Notizen'}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Dimension rating -->
<div
  class="row exercise-row"
  *ngFor="let dimensionPage of dimensionPages"
>
  <div class="col">
    <div class="row align-items-center pb-2">
      <div class="col-6 ps-0">
        <h1>{{exerciseSet.customername}}</h1>
        <h1>{{exerciseSet.name}}</h1>
      </div>
      <div
        class="col-6"
        style="padding-right:0px"
      >
        <img
          *ngIf="!exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          src="assets/ProfilM_Logo.png"
        >
        <img
          *ngIf="exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          [src]="'/api/logo/load/' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id)._id + '?&uploaddate=' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id).uploadDate"
        >
      </div>
    </div>

    <div class="row">
      <div class="col mb-4 border-bottom border-prm"></div>
    </div>

    <div class="row">
      <div
        class="col fw-bold dimension-title ps-0"
        *ngIf="!hasExerciseRoleplayOrCaseStudy(exercise) && !isExerciseInterview(exercise)"
      >{{isEnglish ? 'Competencies
        evaluation': 'Bewertung der Kompetenzen'}}
      </div>
      <div
        class="col fw-bold dimension-title ps-0"
        *ngIf="hasExerciseRoleplayOrCaseStudy(exercise) && !isExerciseInterview(exercise)"
      >{{isEnglish ? 'Competencies
        evaluation': 'Bewertung der Kompetenzen'}}
      </div>
      <div
        class="col fw-bold dimension-title ps-0"
        *ngIf="isExerciseInterview(exercise)"
      >
        {{dimensionPage.dimensions[0].name}}
      </div>
    </div>

    <ng-container *ngFor="let dimension of dimensionPage.dimensions">
      <div class="row mt-3">
        <div class="col ps-0 pe-0">
          <table
            *ngIf="dimension.questions.length > 0"
            class="table dimension-table"
          >
            <thead>
              <tr>
                <th
                  class="prm-background-color"
                  *ngIf="!isExerciseInterview(exercise)"
                >{{dimension.name}}</th>
                <th
                  class="prm-background-color"
                  *ngIf="isExerciseInterview(exercise)"
                >{{isEnglish ? 'Questions':
                  'Fragen'}}</th>
              </tr>
            <tbody>
              <tr *ngFor="let question of dimension.questions">
                <td>{{question.text}}</td>
              </tr>
            </tbody>
          </table>

          <table class="table dimension-table">
            <thead>
              <tr>
                <th class="prm-background-color">
                  <span *ngIf="!isExerciseInterview(exercise)">{{dimension.name}}</span>
                  <span *ngIf="isExerciseInterview(exercise)">{{isEnglish ? 'Rating': 'Bewertung'}}</span>
                </th>
                <th
                  class="prm-background-color text-center"
                  *ngFor="let scale of exerciseSet.scale; index as i"
                >
                  {{scale.value}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let anchor of dimension.anchor">
                <td>{{anchor.text}}</td>
                <td *ngFor="let scale of exerciseSet.scale; index as i"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="fw-bold pt-2 pb-2">
                <td></td>
                <td
                  class="text-end"
                  [attr.colspan]="exerciseSet.scale.length - 1"
                >{{isEnglish ? 'Total': 'Total'}}
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <div
        class="row border fw-bold pt-2 pb-2 prm-background-color"
        *ngIf="isExerciseInterview(exercise)"
      >
        <div class="col text-white">{{isEnglish ? 'Notes': 'Notizen'}}</div>
      </div>
    </ng-container>
  </div>
</div>

<!-- Notes -->
<div class="row exercise-row">
  <div class="col">
    <div class="row align-items-center pb-2">
      <div class="col-6 ps-0">
        <h1>{{exerciseSet.customername}}</h1>
        <h1>{{exerciseSet.name}}</h1>
      </div>
      <div
        class="col-6"
        style="padding-right:0px"
      >
        <img
          *ngIf="!exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          src="assets/ProfilM_Logo.png"
        >
        <img
          *ngIf="exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          [src]="'/api/logo/load/' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id)._id + '?uploaddate=' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id).uploadDate"
        >
      </div>
    </div>

    <div class="row">
      <div class="col mb-4 border-bottom border-prm"></div>
    </div>

    <div class="row mb-3">
      <div class="col notes">
        <div class="row border fw-bold pt-2 pb-2 prm-background-color">
          <div class="col text-white">{{isEnglish ? 'Notes': 'Notizen'}}</div>
        </div>
        <div class="row">
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
          <div class="col-12 pt-2 pb-2 border-bottom dottedBlue">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Strengths and improvements -->
<div class="row exercise-row">
  <div class="col">
    <div class="row align-items-center pb-2">
      <div class="col-6 ps-0">
        <h1>{{exerciseSet.customername}}</h1>
        <h1>{{exerciseSet.name}}</h1>
      </div>
      <div
        class="col-6"
        style="padding-right:0px"
      >
        <img
          *ngIf="!exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          src="assets/ProfilM_Logo.png"
        >
        <img
          *ngIf="exerciseSetService.exsetidToCustomerLogo.has(exerciseSet._id)"
          class="float-end"
          [src]="'/api/logo/load/' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id)._id + '?uploaddate=' + exerciseSetService.exsetidToCustomerLogo.get(exerciseSet._id).uploadDate"
        >
      </div>
    </div>

    <div class="row">
      <div class="col mb-4 border-bottom border-prm"></div>
    </div>

    <div class="row border border-prm">
      <div class="col-6 fw-bold prm-background-color border-bottom border-prm pt-2 pb-2 border-end">
        {{isEnglish ? 'Strengths': 'Stärken'}}
      </div>
      <div class="col-6 fw-bold prm-background-color border-bottom border-prm pt-2 pb-2">{{isEnglish ?
        'Development areas': 'Entwicklungsfelder'}}
      </div>
      <div class="col-6">
        <div class="row border-end border-prm">
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
          <div class="col-12 pt-2 pb-2">&nbsp;</div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col ps-0 pe-0">
        <table class="table dimension-table-small">
          <thead>
            <tr>
              <th
                class="prm-background-color"
                colspan="2"
              >{{isEnglish ? 'Competencies': 'Kompetenzen'}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dimension of exercise.dimensions">
              <td>{{dimension.name}}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>